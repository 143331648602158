import React from 'react';
import Seo from '../../components/layout/SEO';
import Layout from '../../components/layout/LayoutInner';
import InnerHead from '../../components/layout/InnerHead';
import PracticeAreas from '../../components/home/PracticeAreas';
import Pitch from '../../components/home/Pitch';
import Partners from '../../components/home/Partners';

export default function index() {
  return (
    <Layout>
      <Seo
        title='Our Practice Areas'
        canonical='practice-areas/'
      />
      <InnerHead />
      <PracticeAreas />
      <Pitch />
      <Partners />
    </Layout>
  );
}
